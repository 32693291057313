import React, { useEffect, useState } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import Reports from "../template/admin/reports";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import { adminUserReportApi } from "./_actions";
import ReportUserTable from "../template/admin/reportUserTable";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import arraySort from 'array-sort';
import addDays from "date-fns/addDays";

const UsersReport = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalStats, setTotalStats] = useState(null);
  const [allowView, setAllowView] = useState(false);
  const [reportFilteredData, setReportFilteredData] = useState([]);
  const [currentReportList, setCurrentReportList] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [sortObj, setSortObj] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [filterType, setFilterType] = useState('all');



  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };
  // useEffect(() => {
  //   if (reportData) {
  //     callBasicApi(null);
  //   }
  // }, [filterType]);
  const callBasicApi = async (val = null) => {
    globalLoader(true);
    try {
      let obj = val ? val : `fromDate=${addDays(new Date(), -30)}&toDate=${new Date()}`;
      obj = !obj?.includes("&filterType=") ? `${obj}&filterType=${filterType}` : obj;
      let res = await adminUserReportApi(obj);
      if (res?.data?.status === 200) {
        setSearchVal('');
        setAllowView(true);
        setReportData(res?.data?.data?.reportData);
        setTotalStats(res?.data?.data?.totalStats);
        let report = res?.data?.data?.reportData;
        setReportFilteredData(report);
        setTotalCount(res?.data?.data?.reportData.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  const searchFilter = (ev, val) => {
    ev.preventDefault();
    setSearchVal(val)
    let arr = [...reportData];
    let filterArr = arr.filter((data) => (data?.userMobile.toString().toLowerCase().includes(val.toLowerCase()) ||
      data?.count.toString().toLowerCase().includes(val.toLowerCase())));

    setReportFilteredData(filterArr);
    setCurrentReportList(filterArr);

    setTotalCount(filterArr.length);
    setNotFilteredRun(false);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    }
  }, [pageSize, currentPage, reportData, reportFilteredData, sortObj]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    if (reportData?.length > 0) {
      let arr = [...reportData];
      let filterArr = [];

      // let filterArr = arr.filter((data) => (data?.userMobile.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
      //   data?.count.toString().toLowerCase().includes(searchVal.toLowerCase())));

      let filterData = [];

      if (dataArray) {
        arr = [...dataArray]
      }
      else {
        arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
      }

      if (searchVal.length > 0) {
        filterArr = arr.filter((data) => {
          let val = searchVal.toLowerCase();
          if ((data?.userMobile.toString().toLowerCase().includes(val.toLowerCase()) ||
            data?.count.toString().toLowerCase().includes(val.toLowerCase()))) {
            return data;
          } else {
            return null;
          }
        });
      } else {
        filterArr = arr;
      }


      for (
        let i = ((localPage || currentPage) * pageSize) - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr?.length;
        i++
      ) {
        filterData.push(filterArr[i]);
      }
      setCurrentReportList(filterData);
    } else {
      setCurrentReportList([]);
    }

  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(false);
    setPageSize(val.target.value);
    setCurrentPage(1);
  };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage <
      // Math.ceil(currentDataCopy?.length /pageSize)
      Math.ceil(reportData?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...currentReportList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })
    setNotFilteredRun(true);

    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };

  return (
    // <>
    //   {allowView && (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>

      <div className="admin-portal">
        <Header roleType="admin" />
        <div className={`${navbarToggle ? "active" : ""} wrapper`}>
          <SidebarComponent
            navbarToggle={navbarToggle}
            active={props.match.path}
            menu="reports"
            subMenu='users-report'
          />
          <div className="main">
            <div className="main-wrapper">
              <div>
                <p className="admin-banner">Users Report</p>
              </div>
              <SidebarArrow handleSidebarToggle={handleSidebar} />
              <Reports type="user" searchTable={callBasicApi} filterType={filterType} changeFilterType={setFilterType} />
              {reportData && totalStats && (
                <ReportUserTable
                  reportData={currentReportList}
                  totalStats={totalStats}
                  searchFunc={searchFilter}
                  pageSize={pageSize}
                  changePageSize={changePageSizeFunc}
                  goToPage={goToPageFunc}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  applicationType={totalCount > 1 ? "users" : "user"}
                  arraySorting={arraySorting}
                  sortObj={sortObj}
                  allReport={reportData}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    //   )}
    // </>
  );
};

export default UsersReport;
