import React from "react";
import format from "date-fns/format";

const StocksTable = (props) => {
  return (
    <div className="table-wrapper px-3 pt-3">
      <div className="d-flex justify-content-between pb-1 align-items-end">
        <div className="table-type">Stock transactions</div>
      </div>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-own table-responsive">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th>ID</th>
                <th>Stock Edited</th>
                <th>Date</th>
                <th>Time</th>
                <th>Notes</th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props?.dataList &&
                props?.dataList.map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item?.stockQuantity || "N/A"}</td>
                    <td>
                     {item?.timestamp?format(new Date(item?.timestamp), "dd/MM/yyyy"):"N/A"}
                    </td>
                    <td>
                    {item?.timestamp?format(new Date(item?.timestamp), "hh:mm a"):"N/A"}

                    </td>
                    <td>{item?.notes || "N/A"}</td>
                    <td>{item?.adminUser?.email || "N/A"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StocksTable;
