import React from "react";
import { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router";

const TermsComponent = (props) => {

  const [footerHeight, setFooterHeight]=useState(50);
  const ref = useRef();
  useEffect(()=>{
    setFooterHeight(ref.current.offsetHeight)
  })
  return (
    <div>
      {/* <HeadingComponent text="App Terms and Condtions" /> */}
      <div className="fixed-top stripe py-3 border-bottom heading-wrapper">
        <p className="h4 font-weight-bold text-center terms-head">
          App Terms and Condtions
        </p>
      </div>
      <div className="mx-3 text-justify terms px-3 terms-text">
        <p className="font-weight-normal">
          Please read carefully before registering to use the App.{" "}
        </p>
        <p className="font-weight-normal">
          BY CLICKING ON THE "ACCEPT" BUTTON BELOW YOU AGREE TO THESE TERMS
          WHICH WILL BIND YOU.
        </p>
        <p className="font-weight-normal">
          IF YOU DO NOT AGREE TO THESE TERMS, CLICK ON THE "REJECT" BUTTON
          BELOW.
        </p>
        <strong className="font-weight-bold">
          Mobile application end-user agreement
        </strong>
        <p className="font-weight-normal">
          We, Provide CIC, (‘Provide’) license you to use:-
        </p>
        <ul>
          <li>
            The E C Card mobile application software (‘App’) and any updates or
            supplements to it.
          </li>
          <li>
            The service you connect to via the App and the content we provide to
            you through it (‘Service’).
          </li>
        </ul>
        <p className="font-weight-normal">
          As permitted in the terms of this end user licence agreement (‘EULA’)
        </p>
        <p className="font-weight-normal">
          Provide license use of the App and related media to you on the basis
          of this EULA and subject to any rules or policies applied by any
          appstore provider or operator from whose site, located at
          https://play.google.com/store/apps and
          https://www.apple.com/uk/ios/app-store/ , the End-User downloaded the
          App.
        </p>
        <p className="font-weight-normal">
          Provide remain the owners of the App(s) and related media at all
          times.
        </p>
        <p className="font-weight-bold">Operating system requirements</p>
        <p className="font-weight-normal">
          The App requires an Apple or Android Device (‘Device’) with a camera,
          internet access and a minimum of 100MB of memory available. The
          minimum operating systems we support are iOS10 and Android 4.14 Jelly
          Bean. The App(s) may work on other Operating Systems but the Operating
          Systems specified above are the minimum Operating Systems Provide test
          on and guarantee that the App(s) will run for.
        </p>
        <p className="font-weight-bold">Permissions</p>
        <p className="font-weight-normal">
          You will be assumed to have obtained permission from the owners of the
          mobile telephone or handheld devices (‘Device Owner’) that are
          controlled, but not owned, by you to download a copy of the App(s).
          The Device Owner may be charged by their service providers for
          internet access on the Devices. You should check with your mobile or
          internet service provider for details of data and internet usage
          charges. You accept responsibility in accordance with the terms of
          this EULA for the use of the App(s) or any Service(s) on or in
          relation to any Device, whether or not it is owned by you.
        </p>
        <p className="font-weight-bold">Requirements of the Service</p>
        <p className="font-weight-normal">
          To be eligible to receive free condoms through the Service you must be
          between 16 and 24 years old and resident in a valid postcode area*.
          Users will be required to successfully complete the quiz within as
          part of the registration process. Restrictions apply to the number and
          frequency of condoms that can be collected. Locations offering the
          Service will vary and may change without notice.
        </p>
        <p className="font-weight-normal">
          The Service is supported by the sexual health service clinical team.
          If you have any concerns questions or queries about sexual health and
          contraception, or if you are affected by any content in this app
          please contact your local sexual health service.
        </p>
        <p className="font-weight-normal">
          The educational content of the App is for provided for educational
          purposes only and should not be considered as an alternate to seeking
          advice from a qualified health care provider.
        </p>
        <p className="font-weight-normal">
          From time to time updates to the App(s) may be issued. Depending on
          the update, you may not be able to use the Services until you have
          downloaded or streamed the latest version of the App(s) and accepted
          any new terms.
        </p>
        <p className="font-weight-bold">Your information</p>
        <p className="font-weight-normal">
          Provide only use any personal data we collect through your use of the
          App and the Services in the ways set out in our privacy policy
          https://www.provide.org.uk/your-information/
        </p>
        <p className="font-weight-normal">
          Please be aware that internet transmissions are never completely
          private or secure and that any message or information you send using
          the App or any Service may be read or intercepted by others, even if
          there is a special notice that a particular transmission is encrypted
        </p>
        <p className="font-weight-normal">
          As part of the registration process we record the following
          information: date of birth, postcode and gender. Details of all
          transactions are also gathered including the location, date, time and
          quantity of condoms collected.
        </p>
        <p className="font-weight-normal">
          By using the App or any of the Service(s), you consent to us
          collecting and using technical information about the Devices and
          related software, hardware and peripherals for Services that are
          internet-based or wireless to improve our products and to provide any
          Service(s) to you.
        </p>
        <p className="font-weight-bold">Acceptable use restrictions</p>
        <p className="font-weight-normal">You must:</p>
        <ul>
          <li>
            not use the App or any Service in any unlawful manner, for any
            unlawful purpose, or in any manner inconsistent with these terms, or
            act fraudulently or maliciously, for example, by hacking into or
            inserting malicious code, such as viruses, or harmful data, into the
            App, any Service or any operating system;
          </li>
          <li>
            not infringe our intellectual property rights or those of any third
            party in relation to your use of the App or any Service (to the
            extent that such use is not licensed by these terms);
          </li>
          <li>
            not transmit any material that is defamatory, offensive or otherwise
            objectionable in relation to your use of the App or any Service;
          </li>
          <li>
            not use the App or any Service in a way that could damage, disable,
            overburden, impair or compromise our systems or security or
            interfere with other users; and
          </li>
          <li>
            not collect or harvest any information or data from any Service or
            our systems or attempt to decipher any transmissions to or from the
            servers running any Service.
          </li>
        </ul>
        <p className="font-weight-bold">Intellectual property rights</p>
        <p className="font-weight-normal">
          You acknowledge that all intellectual property rights in the App(s),
          related media belong to Provide CIC, that rights in the App(s) are
          licensed (not sold) to you, and that you have no rights in, or to, the
          App(s), the media or the Technology other than the right to use each
          of them in accordance with the terms of this EULA.
        </p>
        <p className="font-weight-normal">
          You acknowledge that you have no right to have access to the App(s) in
          source-code form.
        </p>
        <p className="font-weight-bold">Limitation of liability</p>
        <p className="font-weight-normal">
          Although Provide make reasonable efforts to update the information in
          the App(s), we make no representations, warranties or guarantees,
          whether express or implied that the content of the App(s) is accurate,
          complete or up-to-date.
        </p>
        <p className="font-weight-normal">
          You acknowledge that the information may be updated, altered or
          removed, from time to time, at any time, without notice to you.
        </p>
        <p className="font-weight-normal">
          Provide are not responsible for any data usage, roaming or other
          charges you incur when accessing the internet through your Device, as
          specified in condition.
        </p>
        <p className="font-weight-normal">
          The App and media is for domestic and private use. You agree not to
          use the App(s) and Media for any commercial, business or resale
          purposes, and Provide have no liability to you for any loss of profit,
          loss of business, business interruption, or loss of business
          opportunity.
        </p>
        <p className="font-weight-normal">
          {" "}
          Nothing in this EULA shall limit or exclude our liability for:
        </p>
        <ul>
          <li>death or personal injury resulting from our negligence;</li>
          <li>fraud or fraudulent misrepresentation;</li>
          <li>
            any other liability that cannot be excluded or limited by English
            law.
          </li>
        </ul>
        <p className="font-weight-bold">No rights for third parties</p>
        <p className="font-weight-normal">
          This agreement does not give rise to any rights under the Contracts
          (Rights of Third Parties) Act 1999 to enforce any term of this
          agreement.
        </p>
        <p className="font-weight-bold">
          If a court finds part of this contract illegal, the rest will continue
          in force
        </p>
        <p className="font-weight-normal">
          Each of the paragraphs of these terms operates separately. If any
          court or relevant authority decides that any of them are unlawful, the
          remaining paragraphs will remain in full force and effect. Governing
          law These terms are governed by English law and you can bring legal
          proceedings in respect of the Service in the English courts.
        </p>
        <p className="font-weight-bold">Governing Law</p>
        <p className="font-weight-normal">
          These terms are governed by English law and you can bring legal
          proceedings in respect of the Service in the English courts.
        </p>
        <p className="font-weight-bold">Acceptance</p>
        <p className="font-weight-normal">
          By registering with the App and clicking the "accept" button below You
          agree to the terms of this EULA which will bind you. If you do not
          agree to the terms of this EULA, you must stop the process now.
          *Postcode area: Currently this is restricted to the Essex postcode
          area.
        </p>
        <p className="font-italic pt-5">
          *Postcode area: Currently this is restricted to the Essex postcode
          area.
        </p>
        <p className="font-weight-bold text-right">April 2020</p>
      </div>
      <div style={{minHeight:`${footerHeight}px`}}>
      <div className="fixed-bottom stripe py-3 border-top" ref={ref}>
        <div className="col-md-12 text-center">
          {props?.roleType !== "user" && (
            <div className="btn-wrapper d-flex flex-wrap justify-content-around align-items-center">
            <button
              className="btnFooter footer-width py-2 px-2 btn-block-xs min-width-btn-md"
              onClick={() => props?.accept()}
            >
              Accept and Close
            </button>

            <button
              className="footer-width btn btn-own mt-4 mt-md-0 btn-block-xs grey-btn py-2 px-2 min-height-lg min-width-btn-md"
              onClick={() => props.history.push('/user/login')}
            >
              Cancel
            </button>
            </div>
          )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(TermsComponent);
