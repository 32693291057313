import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { GENDER_LIST } from "../../actionTypes/types";
import DatePicker from "react-date-picker";
import { globalLoader } from "../../actions/commonActions";
import InputComponent from "../_common/inputComponent";
import { TextField } from "@mui/material";

const AddNotesModal = (props) => {
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setPropsData();
  }, [props?.data]);

  const setPropsData = () => {
    setNotes(props?.data?.notes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let obj = {
      notes: notes,
    };
    props.closeModal(obj);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-md" }}
    >
      <div>
        <p className="admin-banner">Add Notes</p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          <div className="col-12">
            <div className="form-own-group">
              <InputComponent
                placeholder="Notes"
                label="Notes"
                type="text"
                theme="white"
                value={notes}
                handleCallback={(ev) => {
                  setNotes(ev);
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="admin-btn">
              <button
                onClick={() => props.closeModal()}
                className="btn secondary-btn mr-2"
                type="button"
              >
                Back
              </button>
              <button type="submit" className="btn primary-btn ml-2">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddNotesModal;
