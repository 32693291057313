/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import Reports from "../template/admin/reports";
import SidebarArrow from "../template/_common/sidebarArrow";
import { Helmet } from "react-helmet";
import { globalAlert, globalLoader } from "../actions/commonActions";
import { adminStoreReportApi } from "./_actions";
import ReportStoreTable from "../template/admin/reportStoreTable";
import { compareValues, logOut } from "../_shared/commonFunction";
import { addDays } from "date-fns";
import arraySort from 'array-sort';



const StoresReport = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [totalStats, setTotalStats] = useState(null);
  const [currentReportList, setCurrentReportList] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [sortObj, setSortObj] = useState({});
  const [reportFilteredData, setReportFilteredData] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const [searchVal, setSearchVal] = useState("");



  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  const callBasicApi = async (val = null) => {
    globalLoader(true);
    try {
      let obj = val ? val : `fromDate=${addDays(new Date(), -30)}&toDate=${new Date()}`;
      obj = !obj?.includes("&filterType=") ? `${obj}&filterType=${filterType}` : obj;
      let res = await adminStoreReportApi(obj);
      if (res?.data?.status === 200) {
        setSearchVal('')
        let report = res?.data?.data?.reportData;
        setReportData(report);
        setReportFilteredData(report);
        setTotalCount(report.length);
        setTotalStats(res?.data?.data?.totalStats);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
    // if (obj) {
    //   globalLoader(true);
    //   try {
    //     let res = await adminStoreReportApi(obj);
    //     if (res?.data?.status === 200) {
    //       setReportData(res?.data?.data?.reportData);
    //       setTotalStats(res?.data?.data?.totalStats);
    //       setAllowView(true);
    //     } else {
    //       globalAlert("error", res?.data?.message);
    //     }
    //     globalLoader(false);
    //   } catch (error) {
    //     globalAlert("error", error?.response?.data?.message);
    //     if (error?.response?.data?.status === 401) {
    //       logOut(props?.history, "/admin/login");
    //     }
    //     globalLoader(false);
    //   }
    // }
  };

  const searchFilter = async (ev, val) => {
    ev.preventDefault();
    let arr = [...reportData];
    let filterArr = arr.filter((data) => {
      if (
        data?.storeName.toLowerCase().includes(val.toLowerCase()) ||
        data?.count.toString().toLowerCase().includes(val.toLowerCase())
      ) {
        return data;
      } else {
        return null;
      }
    });
    setReportFilteredData(filterArr);
    setTotalCount(filterArr.length);
    
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   if (reportData) {
  //     callBasicApi(null);
  //   }
  // }, [filterType]);

  useEffect(() => {
    // if (!notFilteredRun) {
    //   filteredDataFunc();
    // }
    filteredDataFunc();
  }, [pageSize, currentPage, reportData, sortObj, reportFilteredData, searchVal]);

  const filteredDataFunc = (dataArray = null) => {
    let arr = [...reportData];
    let filterArr = [];
    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        if (
          data?.storeName.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.count.toString().toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }
    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      // for (
      //   let i = (localPage || currentPage) * pageSize - pageSize;
      //   i < (localPage || currentPage) * pageSize && i < arr?.length;
      //   i++
      // ) {
      //   filteredData.push(arr[i]);
      // }
    }
    setCurrentReportList(filterArr);
  };

  const changePageSizeFunc = async (val) => {
    
    setPageSize(val.target.value);
    setCurrentPage(1);
  };

  const goToPageFunc = (val, data) => {
    
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage <
      // Math.ceil(currentDataCopy?.length /pageSize)
      Math.ceil(reportData?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...reportData];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })

    
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };


  return (
    // <>
    //   {allowView && (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>

      <div className="admin-portal">
        <Header roleType="admin" />
        <div className={`${navbarToggle ? "active" : ""} wrapper`}>
          <SidebarComponent
            navbarToggle={navbarToggle}
            active={props.match.path}
            menu="reports"
            subMenu='stores-report'
          />
          <div className="main">
            <div className="main-wrapper">
              <div>
                <p className="admin-banner">Venues Report</p>
              </div>
              <SidebarArrow handleSidebarToggle={handleSidebar} />

              <Reports type="stores" searchTable={callBasicApi} filterType={filterType} changeFilterType={setFilterType} />
              {reportData && totalStats && (
                <ReportStoreTable
                  reportData={currentReportList}
                  totalStats={totalStats}
                  pageSize={pageSize}
                  changePageSize={changePageSizeFunc}
                  goToPage={goToPageFunc}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  applicationType={totalCount > 1 ? "venues" : "venue"}
                  arraySorting={arraySorting}
                  sortObj={sortObj}
                  searchFunc={searchFilter}
                  allReport={reportData}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}

                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    //   )}
    // </>
  );
};

export default StoresReport;
