import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLogin from "./adminLogin";
import AdminTerms from "./adminTerms";
import AdminRegister from "./adminRegister";
import ManageUser from "./manageUser";
import ManageStore from "./manageStore";
import AccessCodes from "./accessCodes";
import Quiz from "./quiz";
import StoresReport from "./storesReport";
import UsersReport from "./usersReport";
import UsersDataReport from "./usersDataReport";
import ManageVideos from "./manageVideos";
import ForgotPassword from "./forgotPassword";
import ResetTokenComponent from "./resetPassword";
import VerifyAdmin from "./verifyAdmin";
import ManageAdmin from "./manageAdmin";
import EducationManagement from "./educationManagement";
import NotificationManagement from "./notificationManagement";
import ManageStocks from "./manageStocks";
import ManageIvts from "./manageIvts";
import ManageStocksKiosk from './manageKioskStocks';
import SupportModule from './support';

const AdminRouter = (props) => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/login`} component={AdminLogin} />
      <Route
        exact
        path={`${props.match.path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route
        exact
        path={`${props?.match?.path}/manage-admins`}
        component={ManageAdmin}
      />
      <Route
        exact
        path={`${props.match.path}/reset-token/:id`}
        component={ResetTokenComponent}
      />
      <Route exact path={`${props.match.path}/terms`} component={AdminTerms} />
      <Route
        exact
        path={`${props.match.path}/manage-users`}
        component={ManageUser}
      />
      <Route
        exact
        path={`${props.match.path}/register`}
        component={AdminRegister}
      />
      <Route
        exact
        path={`${props.match.path}/manage-stores`}
        component={ManageStore}
      />
      <Route
        exact
        path={`${props?.match?.path}/manage-stores/:id`}
        component={ManageStocks}
      />
      <Route
        exact
        path={`${props.match.path}/access-codes`}
        component={AccessCodes}
      />
      <Route
        exact
        path={`${props.match.path}/manage-videos`}
        component={ManageVideos}
      />
      <Route exact path={`${props.match.path}/quiz`} component={Quiz} />
      <Route
        exact
        path={`${props.match.path}/venues-report`}
        component={StoresReport}
      />
      <Route
        exact
        path={`${props.match.path}/users-report`}
        component={UsersReport}
      />
      <Route
        exact
        path={`${props.match.path}/users-data-report`}
        component={UsersDataReport}
      />
      <Route
        exact
        path={`${props.match.path}/activate-account`}
        component={VerifyAdmin}
      />
      <Route
        exact
        path={`${props.match.path}/education-management`}
        component={EducationManagement}
      />
      <Route
        exact
        path={`${props.match.path}/notification-management`}
        component={NotificationManagement}
      />
      <Route
        exact
        path={`${props.match.path}/manage-kiosk`}
        component={ManageIvts}
      />

      <Route
        exact
        path={`${props.match.path}/manage-kiosk/:id`}
        component={ManageStocksKiosk}
      />

      <Route
        exact
        path={`${props.match.path}/support`}
        component={SupportModule}
      />


      <Route path={`${props.match.path}`}>
        <Redirect to={`${props.match.path}/manage-users`} />
      </Route>
    </Switch>
  );
};

export default AdminRouter;
