import React, { useEffect, useState } from "react";
import Router from "./router";
import { withRouter } from "react-router-dom";
import Loader from "./_shared/loader";
import AlertComponent from "./_shared/alert";
import { useClearCacheCtx } from "react-clear-cache";
import { clearCache } from "clear-cache";
import { commonAppApi } from "./actions/commonActions";
// import { fetchToken, onMessageListener } from './firebase';

let myInterval;

const App = () => {

  useEffect(() => {
  
    callBasicApi();
  
  }, []);

  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();



  const callBasicApi = async () => {
    await commonAppApi();
  };

  const checkClickEvent = () => {
    clearTimeout(myInterval);   
  };

  if (!isLatestVersion) {
    localStorage.clear();
    // alert("Latest App version found !!! Click to reload the application");
    alert("Latest App version found !!! Reload the application");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // window.location.reload();
    clearCache()
    emptyCacheStorage();
  }

  return (
    <React.Fragment>
      <Loader />
      <AlertComponent />
      <div onClick={checkClickEvent}>
        <Router />
      </div>
    </React.Fragment>
  );
};

export default withRouter(App);
