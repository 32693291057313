import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { globalAlert } from "../../actions/commonActions";
import { STORES_DROPDOWN_LIST } from "../../actionTypes/types";
import compareAsc from "date-fns/compareAsc";
import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";


const Reports = (props) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [toMinDate, setToMinDate] = useState(null);
  const [fromMaxDate, setFromMaxDate] = useState(new Date());

  useEffect(() => {
    let toDt = new Date();
    let fromDt = new Date(new Date().setDate(toDt.getDate() - 30));
    setToDate(toDt);
    setFromDate(fromDt);
    let obj = `fromDate=${fromDt}&toDate=${toDt}`;
    // props?.searchTable(obj);
  }, []);

  useEffect(() => {
    if(fromDate && toDate) {
      let obj = `fromDate=${fromDate}&toDate=${toDate}`;// eslint-disable-next-line
      props?.searchTable(obj);
    }
  }, [fromDate, toDate]);

  const handleFilterChange = (ev) => {
    if(ev?.target?.value) {
      props.changeFilterType(ev?.target?.value)
      const queryString = `fromDate=${fromDate}&toDate=${toDate}&filterType=${ev?.target?.value}`;
      props.searchTable(queryString);
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (fromDate > toDate) {
      flag = false;
    }
    return flag;
  };

  const setFromDateFunc = (val) => {
    setFromDate(val);
    setToMinDate(val);
  };

  const setToDateFunc = (val) => {
    setToDate(val);
    setFromMaxDate(val);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = `fromDate=${fromDate}&toDate=${toDate}`;// eslint-disable-next-line
      props?.searchTable(obj);
    } else {
      globalAlert("Error", "To date less than from date");
      setFromDate(null);
      setToDate(null);
    }
  };
  return (
    <div className="admin-report">
      <form onSubmit={handleSearch} className="form-report">
        <div className="form-report-group">
          <label htmlFor="fromDate">From</label>
          <div className="datepicker-report">
            <DatePicker
              id="fromDate"
              value={fromDate}
              onChange={(ev) => setFromDateFunc(ev)}
              maxDate={fromMaxDate}
              format={"dd/MM/yyy"}
            />
          </div>
        </div>
        <div className="form-report-group">
          <label htmlFor="toDate">To</label>
          <div className="datepicker-report">
            <DatePicker
              id="toDate"
              value={toDate}
              onChange={(ev) => setToDateFunc(ev)}
              maxDate={new Date()}
              format={"dd/MM/yyy"}
              minDate={toMinDate}
            />
          </div>
        </div>
        {/* {props?.type === "stores" &&  */}
        <div className="show-item-page filter-type form-own mr-0 mr-md-3 form-report-group">
          <FormControl variant="outlined">
            <InputLabel id="show_per_page">Filter Type</InputLabel>
            <Select
              labelId="show_per_page"
              id="demo-simple-select-outlined"
              value={props?.filterType || ''}
              onChange={handleFilterChange}
              label="Filter Type"
              name="pageSize"
            >
              {STORES_DROPDOWN_LIST &&
                STORES_DROPDOWN_LIST.length > 0 &&
                STORES_DROPDOWN_LIST.map((data, index) => (
                  <MenuItem value={data.value} key={index}>
                    {data.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </form>
    </div>
  );
};
export default Reports;
