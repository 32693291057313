import React from "react";
import format from "date-fns/format";

const UserTable = (props) => {
  return (
    <div className="table-wrapper px-3 pt-3">
      <p className="table-type">All Users</p>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-responsive table-own">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "mobile" ? "active" : ""
                    }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("mobile")}
                >
                  <span>Phone</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "postalCode" ? "active" : ""
                    }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("postalCode")}
                >
                  <span>Post Code</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "dateOfBirth" ? "active" : ""
                    }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("dateOfBirth")}
                >
                  <span>Date of Birth</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "gender" ? "active" : ""
                    }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("gender")}
                >
                  <span>Gender</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className={`text-center sort-header cursor ${props?.sortObj?.sortVal === "created" ? "active" : ""
                  }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("created")}>
                  <span>Registration Date</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className="text-center">
                  <span>Emergency Override</span>

                </th>
                <th className={`text-center sort-header cursor ${props?.sortObj?.sortVal === "lastOverrideOn" ? "active" : ""
                  }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("lastOverrideOn")}>
                  <span>Last override</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>

                <th className={`text-center sort-header cursor ${props?.sortObj?.sortVal === "totalOverrides" ? "active" : ""
                  }
                ${props?.sortObj?.sorType ? "asec" : "desc"}
                `}
                  onClick={() => props.arraySorting("totalOverrides")}>
                  <span>Total override</span>
                  {props?.sortObj?.sortType === "asec" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>

                <th>
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data?.mobile}</td>
                    <td>{data?.postalCode.toUpperCase()}</td>
                    <td>
                      {data.dateOfBirth
                        ? format(new Date(data?.dateOfBirth), "dd/MM/yyyy")
                        : "NA"}
                    </td>
                    <td className="text-capitalize">
                      {data?.gender && data.gender === "not_disclosed"
                        ? "Not disclosed"
                        : data.gender}
                    </td>
                    <td className="override text-center">
                      {data.created
                        ? format(new Date(data?.created), "dd/MM/yyyy")
                        : "NA"}
                    </td>
                    <td className="override text-center">
                      <span
                        onClick={() => props?.emergencyOverride(data)}
                        className="cursor mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                      >
                        Override
                      </span>
                    </td>
                    <td className="override text-center">
                      {data?.lastOverrideOn}
                    </td>
                    <td className="override text-center">
                      {data?.totalOverrides}
                    </td>
                    <td className="nowrap">
                      <span
                        onClick={() => props?.deleteData(data)}
                        // onClick={() => props?.deleteData(data?.userId)}
                        className="btn btn-own-admin primary-btn btn-sm-own table-action"
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
