import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const SupportViewModal = (props) => {

    return (
        <Modal
            showCloseIcon={false}
            classNames={{
                modal: "modal-own",
                root: "modal-own-wrapper confirm-modal",
            }}
            open={props.open}
            onClose={() => props.onCloseModal()}
            center
            closeOnOverlayClick={true}
        >
            <div className="video-player-wrapper px-2">
                {props.description && (
                    <h3 className="font-20 text-center primary-color py-3">{props.description}</h3>
                )}

                <ul className="list-unstyled">
                    <li>
                        <div className="font-13 font-900">Email</div>
                        <div>{props?.data?.email}</div>
                    </li>
                    <li className="pt-3">
                        <div className="font-13 font-900">Mobile</div>
                        <div>{props?.data?.mobile}</div>

                    </li>
                    <li className="pt-3">
                        <div className="font-13 font-900">Title</div>
                        <div>{props?.data?.title}</div>
                    </li>
                    <li className="pt-3">
                        <div className="font-13 font-900">Message</div>
                        <div>{props?.data?.message}</div>
                    </li>
                    {props?.data?.file &&
                        <li className="pt-3">
                            <div className="font-13 font-900">Attachment</div>
                            <span

                                onClick={() => window.open(props?.data.file, '_blank')}

                                style={{
                                    color: "blue",
                                    padding: 0,
                                    textDecoration: 'underline',
                                    cursor: "pointer"
                                }}>View</span>
                        </li>
                    }
                </ul>

                <div className="pb-3 btn-wrapper text-center pt-3">
                    <button
                        type="button"
                        onClick={() => props.onCloseModal()}
                        className="btn primary-btn mb-3 mb-md-0 full-width-xs-mb btn-own btn-own-grey min-height-btn min-width-btn-md mr-md-3"
                    >
                        CLOSE
                    </button>

                </div>
            </div>
        </Modal>
    );
};

export default SupportViewModal;
