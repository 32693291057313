import React from "react";

const SupportTable = (props) => {
    return (
        <>
            <div className="table-wrapper px-3 pt-3">
                <p className="table-type">Support List</p>
                <div className="table-wrapper-scroll-y table-scrollbar">
                    <div className="table-own table-responsive">
                        <table className="table table-striped table-hover table-admin">
                            <thead className="table-head">
                                <tr>
                                    <th
                                        className={`sort-header cursor ${props?.sortObj?.sortVal === "title" ? "active" : ""
                                            } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                                        onClick={() => props.arraySorting("title")}
                                    >
                                        <span>Title</span>
                                        {props?.sortObj?.sortType === "aesc" && (
                                            <i className="fas fa-caret-down"></i>
                                        )}
                                        {props?.sortObj?.sortType === "desc" && (
                                            <i className="fas fa-caret-up"></i>
                                        )}
                                    </th>
                                    <th
                                        className={`sort-header cursor ${props?.sortObj?.sortVal === "email"
                                            ? "active"
                                            : ""
                                            } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                                        onClick={() => props.arraySorting("email")}
                                    >
                                        <span>Email</span>
                                        {props?.sortObj?.sortType === "aesc" && (
                                            <i className="fas fa-caret-down"></i>
                                        )}
                                        {props?.sortObj?.sortType === "desc" && (
                                            <i className="fas fa-caret-up"></i>
                                        )}
                                    </th>
                                    <th className={`sort-header cursor ${props?.sortObj?.sortVal === "mobile"
                                        ? "active"
                                        : ""
                                        } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                                        onClick={() => props.arraySorting("mobile")}>
                                        <span>Mobile</span>
                                        {props?.sortObj?.sortType === "aesc" && (
                                            <i className="fas fa-caret-down"></i>
                                        )}
                                        {props?.sortObj?.sortType === "desc" && (
                                            <i className="fas fa-caret-up"></i>
                                        )}
                                    </th>
                                    <th className={`sort-header cursor ${props?.sortObj?.sortVal === "message"
                                        ? "active"
                                        : ""
                                        } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                                        onClick={() => props.arraySorting("message")}>
                                        <span>Message</span>
                                        {props?.sortObj?.sortType === "aesc" && (
                                            <i className="fas fa-caret-down"></i>
                                        )}
                                        {props?.sortObj?.sortType === "desc" && (
                                            <i className="fas fa-caret-up"></i>
                                        )}
                                    </th>
                                    {/* <th className="sort-header ">
                                        <span>View</span>
                                    </th> */}
                                    <th className="sort-header text-center">
                                        <span>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-body">
                                {props.dataList.map((data) => {
                                    return (
                                        (
                                            <tr key={data?.supportId}>
                                                <td>{data?.title}</td>
                                                <td>{data?.email}</td>
                                                <td>
                                                    {data?.mobile}
                                                </td>
                                                <td>
                                                    {data?.message}
                                                </td>
                                                {/* <td
                                                    className="cursor"
                                                    onClick={() => {
                                                        props.viewVideo(data);
                                                    }}
                                                >
                                                    <i className="far fa-eye primary-color"></i>
                                                </td> */}
                                                <td className="text-center">

                                                    <span
                                                        onClick={() => props?.openModalFunc(data)}
                                                        className="btn btn-own-admin primary-btn btn-sm-own table-action"
                                                    >
                                                        View
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupportTable;
