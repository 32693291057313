import React from "react";



const StoresTable = (props) => {

  return (
    <div className="table-wrapper px-3 pt-3">
      <div className="d-flex flex-wrap">
        <p className="table-type">All Stores</p>

      </div>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-own table-responsive">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "storeName" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("storeName")}
                >
                  <span>Store Name</span>{" "}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "searchAddress" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("searchAddress")}
                >
                  <span>Address</span>{" "}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "stockQuantity" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("stockQuantity")}
                >
                  <span>Stock Quantity</span>{" "}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className="text-center">
                  <span>Hidden on Map</span>
                </th>
                <th className="text-center">
                  <span>QR Code</span>
                </th>

                <th>
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data) => {
                let address = data?.storeNumber + ", " + data?.street;
                if (data?.address) {
                  address = address + ", " + data?.address;
                }
                address = address + ", " + data?.city;
                return (
                  <tr key={data?.storeId || "N/A"}>
                    <td>{data?.storeName || "N/A"}</td>
                    <td>{address || "N/A"}</td>
                    <td
                      className={`${!data?.stockQuantity || Number(data?.stockQuantity) <= 0
                        ? "color-red"
                        : ""
                        }`}
                    >
                      {data?.stockQuantity}
                    </td>
                    <td className="text-center">
                      {data?.hideOnMap ? (
                        <span className="table-isinactive">Yes</span>
                      ) : (
                        <span className="table-isactive">No</span>
                      )}
                    </td>
                    <td className="text-center">
                      <div
                        className="cursor"
                        onClick={() => props.viewQrData(data?.storeId)}
                      >
                        <i className="far fa-eye primary-color"></i>
                      </div>
                    </td>
                    <td className="nowrap">
                      <span
                        className="mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props?.manageStockData(data)}
                      >
                        Edit Stock
                      </span>{" "}
                      |{" "}
                      <span
                        className="mx-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props.editData(data)}
                      >
                        Edit
                      </span>{" "}
                      |{" "}
                      <span
                        className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props.deleteData(data)}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StoresTable;
