/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import InputComponent from "../_common/inputComponent";
import { validPostCodeFunc } from '../../_shared/commonFunction'


const AddEditIvtsModal = (props) => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [street, setStreet] = useState("");
    const [addressLineTwo, setAddressLineTwo] = useState("");
    const [town, setTown] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [notes, setNotes] = useState("");
    const [stockQuantity, setStockQuantity] = useState("");
    const [isHidden, setIsHidden] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [numberErr, setNumberErr] = useState(false);
    const [streetErr, setStreetErr] = useState(false);
    const [townErr, setTownErr] = useState(false);
    const [postCodeErr, setPostCodeErr] = useState(false);
    const [latErr, setLatErr] = useState(false);
    const [lngErr, setLngErr] = useState(false);
    const [latLenErr, setLatLenErr] = useState(false);
    const [lngLenErr, setLngLenErr] = useState(false);
    const [postalCode, setPostalCode] = useState("");
    const [validPostcodeVal, setValidPostcodeVal] = useState(false);


    useEffect(() => {
        setPropsToState(); // eslint-disable-next-line
    }, [props?.data]);

    const setPropsToState = () => {
        setName(props?.data?.name);
        setNumber(props?.data?.number);
        setStreet(props?.data?.street);
        setAddressLineTwo(props?.data?.address);
        setTown(props?.data?.city);
        setPostalCode(props?.data?.postalCode);
        setLat(props?.data?.latitude);
        setLng(props?.data?.longitude);
        setNotes(props?.data?.notes);
        setIsHidden(props?.data?.hideOnMap);
        setStockQuantity(props?.data?.stockQuantity);
        setValidPostcodeVal(true)
    };

    const handleValidation = () => {
        let flag = true;
        if (!name) {
            flag = false;
            setNameErr(true);
        }
        if (!number) {
            flag = false;
            setNumberErr(true);
        }
        if (!street) {
            flag = false;
            setStreetErr(true);
        }
        if (!town) {
            flag = false;
            setTownErr(true);
        }
        if (!postalCode) {
            flag = false;
            setPostCodeErr(true);
        }
        if (postalCode?.length < 3) {
            flag = false;
        }
        if (!lat) {
            flag = false;
            setLatErr(true);
        }
        if (!lng) {
            flag = false;
            setLngErr(true);
        }
        if (parseInt(lat) <= -180 || parseInt(lat) >= 180) {
            flag = false;
            setLatLenErr(true);
        }
        if (parseInt(lng) <= -180 || parseInt(lng) >= 180) {
            flag = false;
            setLngLenErr(true);
        }
        if (!validPostcodeVal) {
            flag = false;
        }
        return flag;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await handleValidation()) {
            let obj = {
                name: name,
                number: number,
                postalCode: postalCode,
                street: street,
                address: addressLineTwo,
                city: town,
                latitude: lat,
                longitude: lng,
                hideOnMap: isHidden,
                notes: notes,
                stockQuantity: stockQuantity,
            };
            props.closeModal(obj);
        }
    };

    const handleChange = (ev) => {
        if (ev.target.checked) {
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
    };

    const handlePostalCode = async (val) => {
        let data = val;
        if (data.length < 8) {


            let value = data.split(" ").join("");
            setPostalCode(value);
            setValidPostcodeVal(await validPostCodeFunc(value))
        }
    };

    const handleLat = (evt) => {
        // const regex = /^[0-9\b]+$/;
        const regex = /^[0-9\.\-\/]+$/;
        const value = evt;
        if (value === "" || value.match(regex) || value === ".") {
            setLat(value);
        }
    };
    const handlelng = (evt) => {
        // const regex = /^[0-9\b]+$/;
        const regex = /^[0-9\.\-\/]+$/;
        const value = evt;
        if (value === "" || value.match(regex) || value === ".") {
            setLng(value);
        }
    };

    return (
        <Modal
            open={props.open}
            onClose={() => props.closeModal()}
            center
            classNames={{ modal: "admin-modal" }}
        >
            <div>
                <p className="admin-banner">{props?.edit ? "Edit" : "Add"} Kiosk</p>
            </div>
            <form onSubmit={handleSubmit} className="form-own px-3">
                <div className="row">
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="name" className="form-own-label">
                Store Name <span className="color-red">*</span>
              </label>
              <input
                id="name"
                name="name"
                placeholder="Store Name"
                className="form-control"
                onChange={(evt) => {
                  setName(evt.target.value);
                }}
                value={name}
              /> */}
                            <InputComponent
                                placeholder="Kiosk Name"
                                label={
                                    <>
                                        Kiosk Name <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="name"
                                theme="white"
                                value={name}
                                handleCallback={(val) => {
                                    setName(val);
                                }}
                            />
                            <div className="error-text">
                                {!name && nameErr && (
                                    <span>IVTS Name is required</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="number" className="form-own-label">
                Number/Name <span className="color-red">*</span>
              </label>
              <input
                id="number"
                name="number"
                placeholder="Number/Name"
                className="form-control"
                onChange={(evt) => {
                  setNumber(evt.target.value);
                }}
                value={number}
              /> */}
                            <InputComponent
                                placeholder="Machine number"
                                label={
                                    <>
                                        Machine ID <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="number"
                                theme="white"
                                value={number}
                                handleCallback={(val) => {
                                    setNumber(val);
                                }}
                            />
                            <div className="error-text">
                                {!number && numberErr && <span>Machine ID is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="street" className="form-own-label">
                Street <span className="color-red">*</span>
              </label>
              <input
                id="street"
                name="street"
                placeholder="Street"
                className="form-control"
                onChange={(evt) => {
                  setStreet(evt.target.value);
                }}
                value={street}
              /> */}
                            <InputComponent
                                placeholder="Street"
                                label={
                                    <>
                                        Street <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="street"
                                theme="white"
                                value={street}
                                handleCallback={(val) => {
                                    setStreet(val);
                                }}
                            />
                            <div className="error-text">
                                {!street && streetErr && <span>Street is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="addressLineTwo" className="form-own-label">
                Address Line Two
              </label>
              <input
                id="addressLineTwo"
                name="addressLineTwo"
                placeholder="Address Line Two"
                className="form-control"
                onChange={(evt) => {
                  setAddressLineTwo(evt.target.value);
                }}
                value={addressLineTwo}
              /> */}
                            <InputComponent
                                placeholder="Address Line Two"
                                label={<>Address Line Two</>}
                                type="text"
                                name="addressLineTwo"
                                theme="white"
                                value={addressLineTwo}
                                handleCallback={(val) => {
                                    setAddressLineTwo(val);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="town" className="form-own-label">
                Town <span className="color-red">*</span>
              </label>
              <input
                id="town"
                name="town"
                placeholder="Town"
                className="form-control"
                onChange={(evt) => {
                  setTown(evt.target.value);
                }}
                value={town}
              /> */}
                            <InputComponent
                                placeholder="Town/City"
                                label={
                                    <>
                                        Town/City <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="town"
                                theme="white"
                                value={town}
                                handleCallback={(val) => {
                                    setTown(val);
                                }}
                            />
                            <div className="error-text">
                                {!town && townErr && <span>Town/City is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="postCode" className="form-own-label">
                Postcode <span className="color-red">*</span>
              </label>
              <input
                className="form-control"
                name="postCode"
                placeholder="Postcode"
                onChange={(ev) => handlePostalCode(ev)}
                value={postalCode}
                maxLength="10"
              /> */}
                            <InputComponent
                                placeholder="Postcode"
                                label={
                                    <>
                                        Postcode <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="postCode"
                                inputFor="text"
                                theme="white"
                                value={postalCode}
                                handleCallback={(val) => handlePostalCode(val)}
                                maxLength="8"
                                capsValue={true}

                            />
                            <div className="error-text">
                                {!postalCode && postCodeErr && (
                                    <span>Postcode is required</span>
                                )}
                                {postalCode && !validPostcodeVal && (
                                    <span>Invalid Postcode </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="lat" className="form-own-label">
                Latitude <span className="color-red">*</span>
              </label>
              <input
                id="lat"
                name="lat"
                placeholder="Latitude"
                className="form-control"
                onChange={(evt) => {
                  handleLat(evt);
                }}
                value={lat}
              /> */}
                            <InputComponent
                                placeholder="Latitude"
                                label={
                                    <>
                                        Latitude <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="lat"
                                theme="white"
                                value={lat}
                                handleCallback={(val) => {
                                    handleLat(val);
                                }}
                                maxLength={8}
                            />
                            <div className="error-text">
                                {!lat && latErr && <span>Latitude is required</span>}
                                {lat &&
                                    latLenErr &&
                                    (parseInt(lat) <= -180 || parseInt(lat) >= 180) ? (
                                    <span>Latitude should be between -180 and 180</span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            <InputComponent
                                placeholder="Longitude"
                                label={
                                    <>
                                        Longitude <span className="color-red">*</span>
                                    </>
                                }
                                type="text"
                                name="lng"
                                theme="white"
                                value={lng}
                                handleCallback={(val) => {
                                    handlelng(val);
                                }}
                                maxLength={8}
                            />
                            <div className="error-text">
                                {!lng && lngErr && <span>Longitude is required</span>}
                                {lng &&
                                    lngLenErr &&
                                    (parseInt(lng) <= -180 || parseInt(lng) >= 180) ? (
                                    <span>Longitude should be between -180 and 180</span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-own-group">
                            {/* <label htmlFor="notes" className="form-own-label">
                Notes
              </label>
              <input
                id="notes"
                name="notes"
                placeholder="Notes"
                className="form-control"
                onChange={(evt) => {
                  setNotes(evt.target.value);
                }}
                value={notes}
              /> */}
                            <InputComponent
                                placeholder="Notes"
                                label={<>Notes</>}
                                type="text"
                                name="notes"
                                theme="white"
                                value={notes}
                                handleCallback={(val) => {
                                    setNotes(val);
                                }}
                            />
                            <div className="error-text"></div>
                        </div>
                    </div>
                    {(!props?.edit) && (
                        <div className="col-6">
                            <div className="form-own-group">
                                <InputComponent
                                    placeholder="Stock Quantity"
                                    label={<>Stock Quantity</>}
                                    type="number"
                                    name="stockQuantity"
                                    theme="white"
                                    value={stockQuantity}
                                    handleCallback={(val) => {
                                        setStockQuantity(val);
                                    }}
                                    maxLength={5}
                                />
                            </div>
                        </div>
                    )}
                    <div className="col-12">
                        <div className="form-own-group">
                            <label htmlFor="" className="form-own-label">
                                Hide on map
                            </label>
                            {/* <input
                type="checkbox"
                id="isHidden"
                name="isHidden"
                checked={isHidden}
                onChange={handleChange}
              /> */}
                            <input
                                className="toggle-input"
                                type="checkbox"
                                id="switch"
                                onChange={(ev) => {
                                    handleChange(ev);
                                }}
                                checked={isHidden}
                            />
                            <label className="toggle-label" htmlFor="switch">
                                Toggle
                            </label>
                        </div>
                    </div>
                </div>
                <div className="admin-btn">
                    <button
                        onClick={() => props.closeModal()}
                        className="btn secondary-btn mr-2"
                        type="button"
                    >
                        Back
                    </button>
                    <button type="submit" className="btn primary-btn ml-2">
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddEditIvtsModal;
