import React, { useState } from "react";
import { CSVLink } from "react-csv";
import PaginationOwn from "../_common/ownPagination";
import InputComponent from "../_common/inputComponent";

const ReportUserDataTable = (props) => {
  const [searchVal, setSearchVal] = useState("");
  const headers = [
    { label: "Date of service user registration", key: "userRegisterDate" },
    { label: "Date of service user transaction", key: "transactionTime" },
    { label: "Type", key: "type" },
    { label: "Gender of service user", key: "userGender" },
    { label: "Date of birth of service user", key: "userDob" },
    { label: "Postcode of service user", key: "userPostalCode" },
    { label: "Phone Number of service user", key: "userMobile" },
    { label: "Venue Location Name", key: "storeName" },
    { label: "Venue Location Street", key: "storeStreet" },
    { label: "Venue Location Address", key: "storeAddress" },
    { label: "Venue Location City", key: "storeCity" },
    { label: "Venue Location Postcode", key: "storePostalCode" },
  ];
  return (
    <>
      <div className="pt-4 admin-controls">
        <form
          onSubmit={(ev) =>
            // eslint-disable-next-line
            props?.searchFunc(ev, props?.searchVal)}
          className="col-12 col-lg-6 admin-search"
        >
          <div className="search-wrapper">
            <InputComponent
              placeholder="Search User"
              label="Search User"
              type="text"
              name="search"
              theme="admin"
              value={props?.searchVal}
              handleCallback={(val) => {
                // eslint-disable-next-line
                props?.setSearchVal?.(val);
              }}
            />
          </div>
          <button type="submit" className="btn secondary-btn">
            Go
          </button>
        </form>
      </div>
      <div className="report-wrapper user-data-wrapper">
        {props?.reportData?.length > 0 ? (
          <div className="report-table-wrapper">
            <div className="download-btn pb-3 pb-md-4 pt-3">
              <CSVLink
                className="btn admin-btn secondary-btn"
                data={props?.allReport}
                filename={"user-data-report.csv"}
                headers={headers}
              >
                Download CSV
              </CSVLink>
              {props?.reportData?.length > 0 && (
                <div className="px-0 col-12 pt-3">
                  <PaginationOwn
                    pageSize={props?.pageSize}
                    changePageSize={props?.changePageSize}
                    goToPage={props?.goToPage}
                    totalCount={props?.totalCount}
                    currentPage={props?.currentPage}
                    applicationType={props?.applicationType}
                    paginationType="report"
                  />
                </div>
              )}
            </div>

            <p className="report-type">User condom usage</p>
            <div>
              <div className="table-responsive table-own">
                <table className="table table-striped table-hover table-admin">
                  <thead className="user-data-report">
                    <tr>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "userRegisterDate"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() =>
                          // eslint-disable-next-line
                          props?.arraySorting("userRegisterDate")}
                      >
                        <span>Date of service user registration</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "type"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() =>
                          // eslint-disable-next-line
                          props?.arraySorting("type")}
                      >
                        <span>Type</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "transactionTime"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("transactionTime")}
                      >
                        <span>Date of service user transaction</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "userGender"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("userGender")}
                      >
                        <span>Gender of service user</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "userDob" ? "active" : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("userDob")}
                      >
                        <span>Date of birth of service user</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "userPostalCode"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("userPostalCode")}
                      >
                        <span>Postcode of service user</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "userMobile"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("userMobile")}
                      >
                        <span>Phone Number of service user</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "storeName" ? "active" : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("storeName")}
                      >
                        <span>Venue Location Name</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "storeStreet"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("storeStreet")}
                      >
                        <span>Venue Location Street</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "storeAddress"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("storeAddress")}
                      >
                        <span>Venue Location Address</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "storeCity" ? "active" : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("storeCity")}
                      >
                        <span>Venue Location City</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                      <th
                        className={`sort-header cursor ${props?.sortObj.sortVal === "storePostalCode"
                          ? "active"
                          : ""
                          } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                        onClick={() => props?.arraySorting("storePostalCode")}
                      >
                        <span>Venue Location Postcode</span>
                        {props?.sortObj?.sortType === "aesc" && (
                          <i className="fas fa-caret-down"></i>
                        )}
                        {props?.sortObj?.sortType === "desc" && (
                          <i className="fas fa-caret-up"></i>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {props?.reportData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {data.userRegisterDate
                              ? data?.userRegisterDate
                              : "N/A"}
                          </td>
                          <td>{data?.type || "N/A"}</td>
                          <td>
                            {data?.transactionTime
                              ? data?.transactionTime
                              : "N/A"}
                          </td>
                          <td>{data?.userGender}</td>
                          <td>{data?.userDob ? data?.userDob : "N/A"}</td>
                          <td>{data?.userPostalCode}</td>
                          <td>{data?.userMobile}</td>
                          <td>{data?.storeName}</td>
                          <td>{data?.storeStreet}</td>
                          <td>{data?.storeAddress}</td>
                          <td>{data?.storeCity}</td>
                          <td>{data?.storePostalCode}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="pr-3 w-100 pt-3">
            <div className="no-data-available w-100">No data found</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportUserDataTable;
